define("discourse/plugins/discourse-custom-wizard/discourse/components/wizard-subscription-cta", ["exports", "@ember/component", "@ember/service", "@ember/object", "@glimmer/component", "@ember/template-factory"], function (_exports, _component, _service, _object, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _class, _descriptor;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <DButton
    @action={{this.click}}
    class="btn btn-pavilion-support {{this.subscription.subscriptionType}}"
    @title={{this.title}}
    @label={{this.label}}
    @icon={{this.icon}}
  />
  */
  {
    "id": "dOs7qvZu",
    "block": "[[[8,[39,0],[[16,0,[29,[\"btn btn-pavilion-support \",[30,0,[\"subscription\",\"subscriptionType\"]]]]]],[[\"@action\",\"@title\",\"@label\",\"@icon\"],[[30,0,[\"click\"]],[30,0,[\"title\"]],[30,0,[\"label\"]],[30,0,[\"icon\"]]]],null]],[],false,[\"d-button\"]]",
    "moduleName": "discourse/plugins/discourse-custom-wizard/discourse/components/wizard-subscription-cta.hbs",
    "isStrictMode": false
  });
  let WizardSubscriptionCta = _exports.default = (_dec = (0, _object.computed)("subscription.subscribed"), _dec2 = (0, _object.computed)("subscription.subscribed"), _dec3 = (0, _object.computed)("i18nKey"), _dec4 = (0, _object.computed)("i18nKey"), (_class = class WizardSubscriptionCta extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "subscription", _descriptor, this);
    }
    get i18nKey() {
      return `admin.wizard.subscription.cta.${this.subscription.subscribed ? "subscribed" : "none"}`;
    }
    get icon() {
      return this.subscription.subscribed ? "far-life-ring" : "external-link-alt";
    }
    get title() {
      return `${this.i18nKey}.title`;
    }
    get label() {
      return `${this.i18nKey}.label`;
    }
    click() {
      window.open(this.subscription.subscriptionCtaLink, "_blank").focus();
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "subscription", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "i18nKey", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "i18nKey"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "icon", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "icon"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "title", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "title"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "label", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "label"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "click", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "click"), _class.prototype)), _class));
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, WizardSubscriptionCta);
});